/* This example requires Tailwind CSS v2.0+ */
import {Fragment} from 'react';
import React from 'react';
import {Popover, Transition} from '@headlessui/react';
import {
  ChartBarIcon,
  CursorClickIcon,
  DocumentReportIcon,
  MenuIcon,
  RefreshIcon,
  EyeOffIcon,
  ShieldCheckIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline';
import {ChevronDownIcon} from '@heroicons/react/solid';
import Footer from './footer';

const solutions = [
  // {
  //   name: 'Appsuite',
  //   description: 'Group of application to enhance your Salesforce usage',
  //   href: '/products/appsuite',
  //   icon: DocumentReportIcon,
  // },
  {
    name: 'Realtask',
    description: 'Feature rich task executor for Salesforce',
    href: '/products/realtask',
    icon: ChartBarIcon,
  },
  {
    name: 'Realsync',
    description: 'Near realtime data sync from Salesforce to external target',
    href: '/products/realsync',
    icon: RefreshIcon,
  },
  {
    name: 'Gooddata',
    description: 'Salesforce in-place Data masking solution',
    href: '/products/gooddata',
    icon: EyeOffIcon,
  },
  {
    name: 'Realfire',
    description: 'Swiss army knife for Salesforce',
    href: '/products/realfire',
    icon: ViewGridIcon,
  },
];
const resources = [{name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#'}];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Layout({children}) {
  return (
    <div>
      <Popover className="relative bg-white">
        <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
          <div>
            <a href="#" className="flex">
              <span className="sr-only">Workflow</span>
              <img className="h-8 w-auto sm:h-10" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="" />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
            <Popover.Group as="nav" className="flex space-x-10">
              <Popover className="relative">
                {({open}) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      )}
                    >
                      <span>Products</span>
                      <ChevronDownIcon
                        className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                            {solutions.map((item) => (
                              <a key={item.name} href={item.href} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                  <item.icon className="h-6 w-6" aria-hidden="true" />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-gray-900">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              {/*<a href="/customers" className="text-base font-medium text-gray-500 hover:text-gray-900">*/}
              {/*  Customers*/}
              {/*</a>*/}
              <a href="/resources/contact" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Contact Us
              </a>
              <a href="https://docs.datasert.com" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Docs
              </a>
              <a href="/resources/support" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Support
              </a>

              {/*<Popover className="relative">*/}
              {/*  {({open}) => (*/}
              {/*    <>*/}
              {/*      <Popover.Button*/}
              {/*        className={classNames(*/}
              {/*          open ? 'text-gray-900' : 'text-gray-500',*/}
              {/*          'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'*/}
              {/*        )}*/}
              {/*      >*/}
              {/*        <span>More</span>*/}
              {/*        <ChevronDownIcon*/}
              {/*          className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}*/}
              {/*          aria-hidden="true"*/}
              {/*        />*/}
              {/*      </Popover.Button>*/}

              {/*      <Transition*/}
              {/*        as={Fragment}*/}
              {/*        enter="transition ease-out duration-200"*/}
              {/*        enterFrom="opacity-0 translate-y-1"*/}
              {/*        enterTo="opacity-100 translate-y-0"*/}
              {/*        leave="transition ease-in duration-150"*/}
              {/*        leaveFrom="opacity-100 translate-y-0"*/}
              {/*        leaveTo="opacity-0 translate-y-1"*/}
              {/*      >*/}
              {/*        <Popover.Panel className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-xs sm:px-0">*/}
              {/*          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">*/}
              {/*            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">*/}
              {/*              {resources.map((item) => (*/}
              {/*                <a key={item.name} href={item.href} className="-m-3 p-3 block rounded-md hover:bg-gray-50">*/}
              {/*                  <p className="text-base font-medium text-gray-900">{item.name}</p>*/}
              {/*                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>*/}
              {/*                </a>*/}
              {/*              ))}*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*        </Popover.Panel>*/}
              {/*      </Transition>*/}
              {/*    </>*/}
              {/*  )}*/}
              {/*</Popover>*/}
            </Popover.Group>
            <div className="flex items-center md:ml-12">
              <a href="https://app.datasert.com/account/login" target="_newtab" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Log In
              </a>
              <a
                href="https://app.datasert.com/account/signup"
                target="_newtab"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Sign up
              </a>
              <a
                href="/products/realfire/download"
                className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Download Realfire
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg" alt="Workflow" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-6">
                    {solutions.map((item) => (
                      <a key={item.name} href={item.href} className="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5">
                <div className="grid grid-cols-2 gap-4">
                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Pricing
                  </a>

                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Docs
                  </a>

                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Enterprise
                  </a>
                  {resources.map((item) => (
                    <a key={item.name} href={item.href} className="text-base font-medium text-gray-900 hover:text-gray-700">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6">
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Sign up
                  </a>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?{' '}
                    <a href="#" className="text-indigo-600 hover:text-indigo-500">
                      Sign in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      {children}
      <Footer />
    </div>
  );
}
